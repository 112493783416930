.three-d-order-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.order-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-section {
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-section h2 {
  margin-bottom: 1.5rem;
  color: #333;
}

input, select, textarea {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.file-upload {
  margin-bottom: 1.5rem;
}

.file-upload label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.file-upload input[type="file"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.file-name {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.preview-container {
  width: 100%;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 1rem;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .three-d-order-container {
    padding: 1rem;
  }
  
  .form-section {
    padding: 1rem;
  }
}

.success-message {
  color: #28a745;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} 